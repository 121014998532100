<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Ranking</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Ranking da
                  <span>Plataforma</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h4 class="aluno_font_color">
                    Parâmetros do ranking
                  </h4>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-bordered table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th>
                          <small class="font-weight-bold">Atividade</small>
                        </th>
                        <th>
                          <small class="font-weight-bold">Descrição</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Pontos</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Completar atividade geral
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá para completar qualquer atividade das aulas de um curso.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_atividade_geral"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Questão correta no QUIZ
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá ao acertar uma questão em uma atividade do tipo QUIZ.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_acerto_questao_quiz"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold text-danger">
                          Questão errada no QUIZ
                        </td>
                        <td class="align-middle">
                          Define quantos pontos o aluno receberá ou perderá ao errar uma questão em uma atividade do tipo QUIZ.
                          <b
                            class="text-danger"
                          >Você pode inserir um valor negativo</b>.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_erro_questao_quiz"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Completar curso
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá após realizar 100% das atividades do curso.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_completar_curso"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Completar curso acima da média
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá se atingir uma nota acima de média geral. Válido apenas para cursos com nota.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_completar_curso_acima_media"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Completar matriz
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá ao completar uma matriz de capacitação.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_completar_matriz"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Completar avaliação
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá após terminar uma avaliação.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_completar_prova"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Completar avaliação acima da média
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá após terminar a avaliação e sua nota for acima de 70% de acertos.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_completar_prova_acima_media"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle font-weight-bold">
                          Participação em live
                        </td>
                        <td
                          class="align-middle"
                        >
                          Define quantos pontos o aluno receberá ao participar de uma live.
                        </td>
                        <td class="text-center align-middle">
                          <input
                            v-model="fastRankingParametros.ponto_participar_live"
                            class="form-control text-center"
                            type="number"
                            @blur="editarRankingParametros"
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h4 class="aluno_font_color">
                    Lista de níveis
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showModal('modalCriarRankingNivel')"
                  >
                    <small>+ Adicionar Nível</small>
                  </button>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          <small class="font-weight-bold">Nível</small>
                        </th>
                        <th>
                          <small class="font-weight-bold">Nome</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Imagem</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ponto inicial</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ponto final</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ações</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastRanking.length">
                      <tr
                        v-for="r in fastRanking"
                        :key="r.nivel"
                      >
                        <td class="text-center align-middle">
                          <small>{{ r.nivel }}</small>
                        </td>
                        <td class="align-middle">
                          <small>{{ r.nome_nivel }}</small>
                        </td>
                        <td class="text-center align-middle">
                          <small>
                            <img
                              :src="r.imagem_nivel"
                              width="70px"
                            >
                          </small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ r.ponto_inicial }}</small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ r.ponto_final }}</small>
                        </td>
                        <td class="text-center align-middle">
                          <button
                            class="btn btn-sm btn-primary mr-2"
                            @click="exibeModalEditarRankingNivel(r)"
                          >
                            <small>Editar</small>
                          </button>
                          <button
                            class="btn btn-sm btn-danger"
                            @click="exibeModalExcluirRankingNivel(r)"
                          >
                            <small>Excluir</small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal
      name="modalCriarRankingNivel"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar nível no ranking</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRankingNivel')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nível</label>
              <input
                v-model="fastRankingNovo.nivel"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome do nível</label>
              <input
                v-model="fastRankingNovo.nome_nivel"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Ponto inicial</label>
              <input
                v-model="fastRankingNovo.ponto_inicial"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Ponto final</label>
              <input
                v-model="fastRankingNovo.ponto_final"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <div class="row">
                <div class="col-12 text-center">
                  <label class="d-block">Imagem do nível</label>
                  <img
                    v-if="fastRankingNovo.imagem_nivel"
                    class="d-block mb-2 m-auto"
                    :src="fastRankingNovo.imagem_nivel"
                    style="max-width: 100%"
                  >
                </div>
              </div>

              <div class="row pl-3 pr-3 mt-2">
                <div class="col-12 p-0">
                  <input
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageRankingNovo"
                  >
                  <label class="message-field-label mt-2">Dimensões sugeridas: 70 x 70 pixels</label>                          
                  <label
                    class="custom-file-label"
                    for="banner_meus_cursos"
                  >Selecione uma imagem...</label>
                  <div class="invalid-feedback">
                    Arquivo inválido
                  </div>
                  <modal
                    name="modalImageCropRankingNovo"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                  >
                    <div class="row">
                      <div class="col-12 pt-4 text-center">
                        <h4>Recorte a imagem</h4>
                      </div>
                      <div
                        v-if="fastRankingNovo.imagem_nivel"
                        class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                      >
                        <vue-cropper
                          ref="cropper"
                          :src="fastRankingNovo.imagem_nivel"
                          :auto-crop-area="1"
                          :aspect-ratio="1 / 1"
                        />
                        <div class="text-center mt-2">
                          <a
                            class="btn btn-primary"
                            href="#"
                            role="button"
                            @click.prevent="cropImageRankingNovo()"
                          >Recortar</a>
                        </div>
                      </div>
                    </div>
                  </modal>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="criarRankingNivel()"
                >
                  <small>Criar nível</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarRankingNivel"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar nível no ranking</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarRankingNivel')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nível</label>
              <input
                v-model="fastRankingEditar.nivel"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome do nível</label>
              <input
                v-model="fastRankingEditar.nome_nivel"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Ponto inicial</label>
              <input
                v-model="fastRankingEditar.ponto_inicial"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Ponto final</label>
              <input
                v-model="fastRankingEditar.ponto_final"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <div class="row">
                <div class="col-12 text-center">
                  <label class="d-block">Imagem do nível</label>
                  <img
                    v-if="fastRankingEditar.imagem_nivel"
                    class="d-block mb-2 m-auto"
                    :src="fastRankingEditar.imagem_nivel"
                    style="max-width: 100%"
                  >
                </div>
              </div>

              <div class="row pl-3 pr-3 mt-2">
                <div class="col-12 p-0">
                  <input
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageRankingEditar"
                  >
                  <label class="message-field-label mt-2">Dimensões sugeridas: 70 x 70 pixels</label>
                  <label
                    class="custom-file-label"
                    for="banner_meus_cursos"
                  >Selecione uma imagem...</label>
                  <div class="invalid-feedback">
                    Arquivo inválido
                  </div>
                  <modal
                    name="modalImageCropRankingEditar"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                  >
                    <div class="row">
                      <div class="col-12 pt-4 text-center">
                        <h4>Recorte a imagem</h4>
                      </div>
                      <div
                        v-if="fastRankingEditar.imagem_nivel"
                        class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                      >
                        <vue-cropper
                          ref="cropper"
                          :src="fastRankingEditar.imagem_nivel"
                          :auto-crop-area="1"
                          :aspect-ratio="1 / 1"
                        />
                        <div class="text-center mt-2">
                          <a
                            class="btn btn-primary"
                            href="#"
                            role="button"
                            @click.prevent="cropImageRankingEditar()"
                          >Recortar</a>
                        </div>
                      </div>
                    </div>
                  </modal>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarRankingNivel()"
                >
                  <small>Atualizar nível</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirRankingNivel"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir nível?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirRankingNivel')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirRankingNivel()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomeInternoAdminPersonalizarRanking",
  components: {
    VueCropper,
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Ranking
      fastRanking: [],
      fastRankingNovo: {
        id_plataforma: 0,
        imagem_nivel: "",
        nivel: 0,
        nome_nivel: "",
        ponto_final: 0,
        ponto_inicial: 0,
      },
      fastRankingEditar: {
        id_plataforma: 0,
        imagem_nivel: "",
        nivel: "",
        nome_nivel: "",
        ponto_final: "",
        ponto_inicial: "",
      },
      fastRankingExcluir: {
        id_plataforma: 0,
        imagem_nivel: "",
        nivel: "",
        nome_nivel: "",
        ponto_final: "",
        ponto_inicial: "",
      },
      fastRankingParametros: {
        id_plataforma: 0,
        id_ranking_parametro: 0,
        ponto_acerto_questao_quiz: 0,
        ponto_atividade_geral: 0,
        ponto_completar_curso: 0,
        ponto_completar_curso_acima_media: 0,
        ponto_completar_matriz: 0,
        ponto_completar_prova: 0,
        ponto_completar_prova_acima_media: 0,
        ponto_erro_questao_quiz: 0,
        ponto_participar_live: 0,
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastRanking();
          this.getFastRankingParametros();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastRanking() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_ranking/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRanking = obj;
        } else {
          this.fastRanking = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastRankingParametros() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_ranking_parametros/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRankingParametros = obj[0];
        } else {
          this.fastRankingParametros = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    setImageRankingNovo(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropRankingNovo");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastRankingNovo.imagem_nivel = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImageRankingNovo() {
      this.fastRankingNovo.imagem_nivel = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      this.hideModal("modalImageCropRankingNovo");
    },
    setImageRankingEditar(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropRankingEditar");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastRankingEditar.imagem_nivel = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImageRankingEditar() {
      this.fastRankingEditar.imagem_nivel = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      this.hideModal("modalImageCropRankingEditar");
    },
    async criarRankingNivel() {
      let erros = [];
      if (!this.fastRankingNovo.nivel) erros.push("O nível é obrigatório");
      if (!this.fastRankingNovo.nome_nivel)
        erros.push("O nome nível do é obrigatório");
      if (!this.fastRankingNovo.imagem_nivel)
        erros.push("Uma imagem é obrigatório");
      if (!this.fastRankingNovo.ponto_inicial)
        erros.push("O ponto inicial é obrigatório");
      if (!this.fastRankingNovo.ponto_final)
        erros.push("O ponto final é obrigatório");

      this.fastRanking.forEach((e) => {
        if (e.nivel == this.fastRankingNovo.nivel) {
          erros.push("O nível informado já existe");
        }
      });

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastRankingNovo.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_ranking/insere",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastRankingNovo))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastRanking();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Nível criado com sucesso",
          });
          this.hideModal("modalCriarRankingNivel");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async editarRankingNivel() {
      let erros = [];
      if (!this.fastRankingEditar.nivel) erros.push("O nível é obrigatório");
      if (!this.fastRankingEditar.nome_nivel)
        erros.push("O nome nível do é obrigatório");
      if (!this.fastRankingEditar.imagem_nivel)
        erros.push("Uma imagem é obrigatório");
      if (!this.fastRankingEditar.ponto_inicial)
        erros.push("O ponto inicial é obrigatório");
      if (!this.fastRankingEditar.ponto_final)
        erros.push("O ponto final é obrigatório");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastRankingEditar.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_ranking/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastRankingEditar))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastRanking();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Nível atualizado com sucesso",
          });
          this.hideModal("modalEditarRankingNivel");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalEditarRankingNivel(r) {
      this.fastRankingEditar = r;
      this.showModal("modalEditarRankingNivel");
    },
    exibeModalExcluirRankingNivel(r) {
      this.fastRankingExcluir = r;
      this.showModal("modalExcluirRankingNivel");
    },
    async excluirRankingNivel() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_ranking/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastRankingExcluir))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastRanking();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Nível excluído com sucesso",
        });
        this.hideModal("modalExcluirRankingNivel");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async editarRankingParametros() {
      let acao = "atualiza";
      if (!this.fastRankingParametros.id_ranking_parametro) acao = "insere";

      this.fastRankingParametros.id_plataforma = this.$route.params.id_plataforma;
      const fast_ranking_parametros = {
        id_plataforma: this.fastRankingParametros.id_plataforma,
        id_ranking_parametro: 0,
        ponto_acerto_questao_quiz: this.fastRankingParametros
          .ponto_acerto_questao_quiz,
        ponto_atividade_geral: this.fastRankingParametros.ponto_atividade_geral,
        ponto_completar_curso: this.fastRankingParametros.ponto_completar_curso,
        ponto_completar_curso_acima_media: this.fastRankingParametros
          .ponto_completar_curso_acima_media,
        ponto_completar_matriz: this.fastRankingParametros
          .ponto_completar_matriz,
        ponto_completar_prova: this.fastRankingParametros.ponto_completar_prova,
        ponto_completar_prova_acima_media: this.fastRankingParametros
          .ponto_completar_prova_acima_media,
        ponto_erro_questao_quiz: this.fastRankingParametros
          .ponto_erro_questao_quiz,
        ponto_participar_live: this.fastRankingParametros.ponto_participar_live,
      };
      console.log("fast_ranking_parametros", fast_ranking_parametros);
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_ranking_parametros/" + acao,
          this.fastAjaxOptions("POST", JSON.stringify(fast_ranking_parametros))
        );

        let json = await resp.json();
        console.log(json);
        let obj = Array.from(json);
        this.getFastRankingParametros();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Ranking atualizado com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style>
  .message-field-label {
    display: flex;
    align-items: left;
    font-size: 12px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
</style>
